import { createSlice } from '@reduxjs/toolkit';
import { fetchAllCurrencies } from '../../utils/Queries/CurrencyQueries';

const currencySlice = createSlice({
  name: 'currencies',
  initialState: {
    currencies: [],
    loading: false,
    error: null,
  },
  reducers: {
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

const { setCurrencies, setLoading, setError } = currencySlice.actions;

export const fetchCurrencies = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const currencies = await fetchAllCurrencies();
    dispatch(setCurrencies(currencies));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export default currencySlice.reducer;
