import { configureStore } from "@reduxjs/toolkit";
import ProductTrackingSlice from "./ProductTracking";
import WebsiteTrackingSlice from "./WebsiteTracking/WebsiteTracking";
import currencyReducer from "./Currencies";

export default configureStore({
  reducer: {
    websiteTracking: WebsiteTrackingSlice,
    productTracking: ProductTrackingSlice,
    currencies: currencyReducer,

  },
});
