import { Button, Input, Modal, Radio, Select, Row, Col } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "./CreateWebsiteButton.scss";
import "../index.scss";
import { useCreateWebsite } from "../../../utils/Mutations/WebsiteMutation";
import { useGetAllWebsiteOwners } from "../../../utils/Queries/WebsiteOwnerQueries";
import { useGetAllWebsiteBusinessType } from "../../../utils/Queries/WebsiteBusinessTypeQueries";

const { Option } = Select;

const CreateWebsiteButton = ({ ecosystems }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [websiteName, setWebsiteName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [ecosystemId, setEcosystemId] = useState("");
  const [isValid, setIsValid] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [businessType, setBusinessType] = useState(1);
  const [owner, setOwner] = useState(1);

  useEffect(() => {
    setIsValid(websiteName !== "" && websiteUrl !== "" && ecosystemId !== "");
  }, [websiteName, websiteUrl, ecosystemId]);

  const { mutate: createWebsiteOfEcosystem } = useCreateWebsite();

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleOk = useCallback(async () => {
    if (isFirstTime) {
      setIsFirstTime(false);
    }

    if (isValid) {
      setIsModalVisible(false);
      createWebsiteOfEcosystem({
        ecosystemId: ecosystemId,
        websiteName: websiteName,
        websiteUrl: websiteUrl,
        ownerId: owner,
        businessTypeId: businessType,
      });

      setWebsiteName("");
      setWebsiteUrl("");
    }
  }, [
    businessType,
    createWebsiteOfEcosystem,
    ecosystemId,
    isFirstTime,
    isValid,
    owner,
    websiteName,
    websiteUrl,
  ]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleAddWebsiteOnClick = useCallback(() => {
    showModal();
  }, [showModal]);

  const handleCrawlOnChange = useCallback((e) => {}, []);

  const handleEcosystemOnChange = useCallback((ecosystemId) => {
    setEcosystemId(ecosystemId);
  }, []);

  const handleWebsiteBusinessTypeOnChange = useCallback((value) => {
    setBusinessType(value);
  }, []);

  const handleWebsiteOwnerOnChange = useCallback((e) => {
    setOwner(e.target.value);
  }, []);

  const handleWebsiteNameOnChange = useCallback((e) => {
    setWebsiteName(e.target.value);
  }, []);

  const handleWebsiteUrlOnChange = useCallback((e) => {
    setWebsiteUrl(e.target.value);
  }, []);

  const { data: websiteOwners, isSuccess: isSuccessWebsiteOwners } =
    useGetAllWebsiteOwners();

  const {
    data: websiteBusinessTypes,
    isSuccess: isSuccessWebsiteBusinessTypes,
  } = useGetAllWebsiteBusinessType();

  return (
    <>
      <Button className="add-website-btn" onClick={handleAddWebsiteOnClick}>
        + New Website
      </Button>
      <Modal
        closable={false}
        title={
          <Row align="top" justify="space-between">
            <Col>
              <p className="add-website-modal-title">Add Website</p>
            </Col>
            <Col>
              <img
                src="images/add-website-modal-icon.png"
                alt="Add Website modal icon"
                width="25"
                height="25"
              />
            </Col>
          </Row>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName="add-website-modal"
        okText="Save"
        centered
      >
        <p className="modal-label">Website Name</p>
        <Input
          className={`modal-item website modal-input ${
            websiteName === "" && !isFirstTime ? "border-red" : ""
          }`}
          placeholder="Website name"
          onChange={handleWebsiteNameOnChange}
          value={websiteName}
        />
        <p className="modal-label">Site’s main URL</p>
        <Input
          className={`modal-item website modal-input ${
            websiteUrl === "" && !isFirstTime ? "border-red" : ""
          }`}
          placeholder="https://www.example.com"
          onChange={handleWebsiteUrlOnChange}
          value={websiteUrl}
        />
        <p className="modal-item-heading">Do you own this site?</p>
        <Row className="modal-item">
          <Radio.Group
            onChange={handleWebsiteOwnerOnChange}
            value={owner}
            className="modal-radio"
            style={{ width: "100%" }}
          >
            <Row>
              {isSuccessWebsiteOwners &&
                websiteOwners.map((item) => {
                  return (
                    <Col span={12} key={item.id}>
                      <Radio className="modal-radio-choice" value={item.id}>
                        {item.description}
                      </Radio>
                    </Col>
                  );
                })}
            </Row>
          </Radio.Group>
        </Row>
        <Row className="modal-item">
          <Col span={12}>
            <span className="modal-item-heading">Max pages to crawl</span>
          </Col>
        </Row>
        <Row className="modal-item">
          <Col span={8}>
            <Select
              bordered={false}
              defaultValue="All pages"
              style={{ width: "100%" }}
              onChange={handleCrawlOnChange}
              className="modal-dropdown"
            ></Select>
          </Col>
        </Row>
        <Row className="modal-item ">
          <Col span={12}>
            <span className="modal-item-heading">Assign to an Ecosystem</span>
          </Col>
        </Row>
        <Row className="modal-item">
          <Col span={10}>
            <Select
              bordered={false}
              defaultValue="Please select"
              style={{ width: "100%" }}
              onChange={handleEcosystemOnChange}
              className={`modal-dropdown ${
                ecosystemId === "" && !isFirstTime ? "border-red" : ""
              }`}
            >
              {ecosystems?.map((ecosystem) => (
                <Option
                  key={ecosystem.id}
                  className="modal-dropdown-item"
                  value={ecosystem.id}
                >
                  {ecosystem.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="modal-item">
          <Col span={12}>
            <span className="modal-item-heading">Business type</span>
          </Col>
        </Row>
        <Row className="modal-item">
          <Col span={10}>
            <Select
              bordered={false}
              defaultValue="Please select"
              style={{ width: "100%" }}
              onChange={handleWebsiteBusinessTypeOnChange}
              className="modal-dropdown"
            >
              {isSuccessWebsiteBusinessTypes &&
                websiteBusinessTypes.map((item) => {
                  return (
                    <Option className="modal-dropdown-item" value={item.id} key={item.id}>
                      {item.description}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
        <Row className="notice">
          <span className="notice-text">
            Please note that it can take up to 24 hours for the first crawl to
            complete
          </span>
        </Row>
      </Modal>
    </>
  );
};

export default CreateWebsiteButton;
