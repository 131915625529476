import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import SubHeader from "../components/Layout/SubHeader";
import Footer from "../components/Layout/Footer";
import Loading from "../components/Animations/Loading";
import { PostData } from "../services/PostData";
import { GetEcosystems } from "../services/GetEcosystems";
import { ScoreBoard } from "../components/Other/ScoreBoard";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../utils/StringUtil";
import { calculatePriceChange } from "../utils/ProductUtil";
import CustomRevisionLegend from "../components/Chart/CustomChartComponents/CustomRevisionLegend";
import { Row } from "antd";
import ProductDetailChart from "../components/Chart/ProductDetailChart";
import { LoggedInStatus } from "../services/LoggedInStatus";

const ProductRevisions = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ecosystems, setEcosystems] = useState(null);
  const [ecosystemId, setEcosystemId] = useState(null);
  const [product, setProduct] = useState(null);
  const { websiteId, productId } = useParams();

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [productChangeList, setProductChangeList] = useState([]);
  const [tab, setTab] = useState(1);
  const [noOfElement, setNoOfElement] = useState(5);
  const [slice, setSlice] = useState([]);
  const [revisionData, setRevisionData] = useState([]);
  const [revisionChartData, setRevisionChartData] = useState([]);
  const [legendContents, setLegendContents] = useState([]);
  const [hasAnalytics, setHasAnalytics] = useState(false);

  const getRevisions = useCallback(() => {
    const postOptions = {
      postRoute: `/product/${productId}/revisions`,
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      setRevisionData(result.data);
      setProductChangeList(result.data);

      const revisionChartDataTemp = result.data
        .filter((rev) => rev.change.includes("price"))
        .map((rev, i) => ({
          date: format(new Date(rev.created_at), "MMM d yyyy"),
          priceChange: i === result.data.length - 1 ? calculatePriceChange(0, rev.price) : calculatePriceChange(result.data[i + 1].price, rev.price),
          price: rev.price,
        }));

      setRevisionChartData(revisionChartDataTemp);
      setLegendContents([
        {
          id: "priceChange",
          value: revisionChartDataTemp.length,
          color: "#00f9b2",
          label: "Price changes",
        },
      ]);
    });
  }, [productId]);

  const getProduct = useCallback(() => {
    const postOptions = {
      postRoute: `/product/${productId}`,
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      setProduct(result.data);
      getRevisions();
    });
  }, [productId, getRevisions]);

  useEffect(() => {
    const li = LoggedInStatus();
    const getAllEcosystems = async () => {
      const allEcosystems = await GetEcosystems();
      const ce = parseInt(sessionStorage.getItem("ecosystemId"));
      setEcosystemId(ce);
      setEcosystems(allEcosystems);

      allEcosystems.data.forEach((ecosystem) => {
        ecosystem.websites.forEach((website) => {
          if (website.id === websiteId) {
            if (website.analytics_id !== "" && website.analytics_id !== null) {
              setHasAnalytics(true);
            }
          }
        });
      });

      getProduct();
    };
    setLoggedIn(li);
    if (li !== false && li !== "exit") {
      getAllEcosystems();
    }
  }, [websiteId, getProduct]);

  const fieldColorTemplate = (field, backgroundColor) => {
    return (
      <span
        style={{
          color: "#fff",
          backgroundColor,
          padding: "5px 20px",
          borderRadius: "5px",
        }}
      >
        {capitalizeFirstLetter(field)}
      </span>
    );
  };

  const fieldColor = (field) => {
    switch (field) {
      case "SKU":
        return fieldColorTemplate(field, "#ff3e70");
      case "name":
        return fieldColorTemplate(field, "#ff5f51");
      case "currency":
        return fieldColorTemplate(field, "#ffa602");
      case "Availability":
        return fieldColorTemplate(field, "#e82e8e");
      case "description":
        return fieldColorTemplate(field, "#8744b8");
      case "price":
        return fieldColorTemplate(field, "#2f4cbd");
      default:
        return <span className="purpleButton">{field}</span>;
    }
  };

  const sliceElements = useCallback((noOfElement) => {
    setSlice(Object.keys(productChangeList).slice(0, noOfElement));
  }, [productChangeList]);

  const revisionChanges = useCallback(async () => {
    const productChangeListTemp = {};
    revisionData.forEach((revision) => {
      productChangeListTemp[revision.created_at] = [];
      const changes = JSON.parse(revision.change);
      for (const [key, value] of Object.entries(changes)) {
        productChangeListTemp[revision.created_at].push({
          id: revision.id,
          change: key,
          newValue: value,
          date: revision.created_at,
        });
      }
    });

    setProductChangeList(productChangeListTemp);
    setLoading(false);
    sliceElements(noOfElement);
  }, [revisionData, noOfElement, sliceElements]);

  useEffect(() => {
    if (revisionData.length > 0) {
      revisionChanges();
    }
  }, [revisionData, revisionChanges]);

  const seeMore = () => {
    setNoOfElement(noOfElement + 5);
    setLoading(false);
    sliceElements(noOfElement + 5);
  };

  const detailData = (e, id) => {
    const postOptions = {
      postRoute: `/products/${productId}/revisions/${id}`,
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      setRevisionData(result.data);
    });
  };

  const renderLegend = () => {
    return (
      <div className="legend">
        <Row justify="start">
          {legendContents.map((legendContent) => (
            <CustomRevisionLegend
              key={legendContent.id}
              color={legendContent.color}
              id={legendContent.id}
              value={legendContent.value}
              label={legendContent.label}
            />
          ))}
        </Row>
      </div>
    );
  };

  if (loggedIn === false || loggedIn === "exit" || loggedIn === undefined) {
    return <Navigate to="/" />;
  } else if (loading || loggedIn === null || ecosystems === null) {
    return <Loading pad={200} />;
  } else {
    return (
      <Fragment>
        <Header
          updateLogin={setLoggedIn}
          logout={() => setLoggedIn(false)}
          loggedIn={loggedIn}
          ecosystems={ecosystems.data}
          ecosystemId={ecosystemId}
        />
        <SubHeader
          ecosystemId={ecosystemId}
          ecosystemName={
            ecosystems.data.find((e) => e.id === ecosystemId)?.name
          }
          websiteId={websiteId}
          revisionId={product?.web_page_id}
          revisionName={product?.web_page?.last_title}
        />
        <section className="section has-background-light-grey">
          <div className="container">
            <div className="page-heading is-clearfix">
              <h1 className="title is-size-3-desktop is-size-3-tablet is-size-4-mobile" style={{color: "black"}}>
                eCommerce product tracking
              </h1>
            </div>
            <ProductDetailChart
              data={revisionChartData}
              ecosystemId={ecosystemId}
              websiteId={websiteId}
              productId={productId}
              hasAnalytics={hasAnalytics}
              // startDate={startDate}
              // endDate={endDate}
            />
            <div className="tabs">
              <ul>
                <li
                  className={tab === 1 ? "is-active" : ""}
                  onClick={() => setTab(1)}
                >
                  <span>Revision history</span>
                </li>
                <li
                  className={tab === 2 ? "is-active" : ""}
                  onClick={() => setTab(2)}
                >
                  <span>Chart</span>
                </li>
              </ul>
            </div>
            {tab === 1 && (
              <Fragment>
                {Object.keys(productChangeList).length > 0 ? (
                  <Fragment>
                    {slice.map((date) => {
                      return (
                        <Fragment key={date}>
                          <div className="timeline-item is-purple">
                            <div className="timeline-marker is-image is-32x32">
                              <img src={product.image_url} alt="Product" />
                            </div>
                            <div className="timeline-content">
                              <p className="heading has-text-weight-bold">
                                {format(new Date(date), "MMMM d, yyyy")}
                              </p>
                              <ul>
                                {Array.isArray(productChangeList[date]) && productChangeList[date].length > 0 ? (
                                  productChangeList[date].map((change) => (
                                    <li key={change.id}>
                                      {fieldColor(change.change)}: {change.newValue}
                                    </li>
                                  ))
                                ) : (
                                  <li>No changes</li>
                                )}

                              </ul>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  detailData(e, productChangeList[date][0].id);
                                }}
                                className="button is-purple is-small mt-2"
                              >
                                View details
                              </Link>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}


                    <button
                      className="button is-purple is-medium mt-5"
                      onClick={seeMore}
                    >
                      See more
                    </button>
                  </Fragment>
                ) : (
                  <div className="notification is-info is-light">
                    No product revisions found.
                  </div>
                )}
              </Fragment>
            )}
            {tab === 2 && (
              <Fragment>
                {renderLegend()}
                <ScoreBoard product={product} />
              </Fragment>
            )}
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
};

export default ProductRevisions;
