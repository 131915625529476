import { Button, Popover } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrencies } from "../../../store/Currencies";
import PopoverContent from "./PopoverContent";
import "./CurrencyFilter.scss";

const CurrencyFilter = ({ title, setFilters, filters }) => {
  const filterBtn = useRef(null);
  const dispatch = useDispatch();
  const { currencies, loading } = useSelector((state) => state.currencies);
  const [choices, setChoices] = useState([]);
  const [isSelect, setIsSelect] = useState(false);

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, [dispatch]);

  useEffect(() => {
    if (currencies.length > 0) {
      setChoices(currencies.map(currency => ({ id: currency.id, name: currency.name })));
    }
  }, [currencies]);

  return (
    <Popover
      placement="bottomLeft"
      content={
        <PopoverContent
          setIsSelect={setIsSelect}
          setFilters={setFilters}
          filters={filters}
          type={title.toLowerCase()}
          choices={choices}
          isLoading={loading}
          filterBtn={filterBtn}
        />
      }
      trigger="click"
      overlayClassName="multiple-filter-popover"
    >
      <Button
        className={`data-table-filter ${isSelect ? "" : ""}`}
        ref={filterBtn}
      >
        <span>{title}</span>
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="down"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
          className="data-table-filter-arrow"
        >
          <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
        </svg>
      </Button>
    </Popover>
  );
};

export default CurrencyFilter;
