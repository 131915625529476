import { Input, Modal, Row, Col, Radio, Select } from "antd";
import React, { useState, useCallback } from "react";
import { useEditWebsite } from "../../../utils/Mutations/WebsiteMutation";
import "../WebsitePopover.scss";
import { useGetAllWebsiteOwners } from "../../../utils/Queries/WebsiteOwnerQueries";
import { useGetAllEcosystemsOfCurrentUserQuery } from "../../../utils/Queries/EcosystemQueries";
import { useGetAllWebsiteBusinessType } from "../../../utils/Queries/WebsiteBusinessTypeQueries";

const { Option } = Select;

const WebsiteEditModal = ({
  isVisible,
  setIsVisible,
  websiteId,
  currentWebsiteName,
  ecosystemId,
  website,
}) => {
  const [websiteName, setWebsiteName] = useState(currentWebsiteName);
  const [owner, setOwner] = useState(() =>
    website.owner.toLowerCase() === "owner" ? 1 : 2
  );
  const [changeEcosystemId, setChangeEcosystemId] = useState(ecosystemId);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [businessType, setBusinessType] = useState(
    () => website.business_type_id
  );

  const mutation = useEditWebsite(ecosystemId, changeEcosystemId);

  const handleOnOk = () => {
    setIsFirstTime(false);
    if (websiteName !== "") {
      mutation.mutate({
        websiteId: websiteId,
        websiteName: websiteName,
        ecosystemId: ecosystemId,
        ownerId: owner,
        businessTypeId: businessType,
        changeEcosystemId: changeEcosystemId
      });
      setIsVisible(false);
    }
  };

  const handleOnCancel = () => {
    setIsVisible(false);
  };

  const handleOnChange = (e) => {
    setWebsiteName(e.target.value);
  };

  const { data: websiteOwners, isSuccess: isSuccessWebsiteOwners } =
    useGetAllWebsiteOwners();

  const handleWebsiteOwnerOnChange = useCallback((e) => {
    setOwner(e.target.value);
  }, []);

  const handleCrawlOnChange = useCallback((e) => { }, []);

  const handleEcosystemOnChange = useCallback((newEcosystemId) => {
    setChangeEcosystemId(newEcosystemId);
  }, []);

  const {
    data: allEcosystemOfUser,
    isSuccess: isSuccessAllEcosystemOfUser,
    isLoading: isLoadingAllEcosystemOfUser,
  } = useGetAllEcosystemsOfCurrentUserQuery();

  const {
    data: websiteBusinessTypes,
    isSuccess: isSuccessWebsiteBusinessTypes,
  } = useGetAllWebsiteBusinessType();

  const handleWebsiteBusinessTypeOnChange = useCallback((value) => {
    setBusinessType(value);
  }, []);

  return (
    <Modal
      title={<p>Edit Website</p>}
      open={isVisible}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      wrapClassName="website-edit-modal"
      centered
    >
      <p className="website-edit-modal-name">Website Name</p>
      <Input
        className={`website-edit-modal-input modal-item modal-input ${websiteName === "" ? "invalid-input" : ""
          }`}
        onChange={handleOnChange}
        value={websiteName}
      />
      <p className="website-edit-modal-name">Do you own this site?</p>
      <Row className="modal-item">
        <Radio.Group
          onChange={handleWebsiteOwnerOnChange}
          value={owner}
          className="modal-radio"
          style={{ width: "100%" }}
        >
          <Row>
            {isSuccessWebsiteOwners &&
              websiteOwners.map((item) => {
                return (
                  <Col span={12} key={item.id}>
                    <Radio className="modal-radio-choice" value={item.id}>
                      {item.description}
                    </Radio>
                  </Col>
                );
              })}
          </Row>
        </Radio.Group>
      </Row>
      <Row className="modal-item">
        <Col span={12}>
          <span className="website-edit-modal-name">Max pages to crawl</span>
        </Col>
      </Row>
      <Row className="modal-item">
        <Col span={8}>
          <Select
            bordered={false}
            defaultValue="All pages"
            style={{ width: "100%" }}
            onChange={handleCrawlOnChange}
            className="modal-dropdown"
          ></Select>
        </Col>
      </Row>
      <Row className="modal-item ">
        <Col span={12}>
          <span className="website-edit-modal-name">
            Assign to an Ecosystem
          </span>
        </Col>
      </Row>
      <Row className="modal-item">
        <Col span={10}>
          <Select
            bordered={false}
            defaultValue="Please select"
            style={{ width: "100%" }}
            onChange={handleEcosystemOnChange}
            className={`modal-dropdown ${changeEcosystemId === "" && !isFirstTime ? "border-red" : ""
              }`}
            loading={isLoadingAllEcosystemOfUser}
            value={changeEcosystemId}
          >
            {isSuccessAllEcosystemOfUser &&
              allEcosystemOfUser?.map((ecosystem) => (
                <Option
                  key={ecosystem.id}
                  className="modal-dropdown-item"
                  value={ecosystem.id}
                >
                  {ecosystem.name}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Row className="modal-item">
        <Col span={12}>
          <span className="website-edit-modal-name">Business type</span>
        </Col>
      </Row>
      <Row className="modal-item">
        <Col span={10}>
          <Select
            bordered={false}
            style={{ width: "100%" }}
            onChange={handleWebsiteBusinessTypeOnChange}
            className="modal-dropdown"
            value={businessType}
          >
            {isSuccessWebsiteBusinessTypes &&
              websiteBusinessTypes.map((item) => {
                return (
                  <Option
                    className="modal-dropdown-item"
                    value={item.id}
                    key={item.id}
                  >
                    {item.description}
                  </Option>
                );
              })}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
};

export default WebsiteEditModal;
