import React, { useState } from "react";
import { PostData } from "../../services/PostData";
import Cookies from "universal-cookie";
import "./UserProfileModal.scss";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import "./UserProfileModal.scss";

export function UserProfileModal() {
  // State
  let u = JSON.parse(sessionStorage.getItem("user")) ?? null;
  const [user, setUser] = useState(u);

  const navigate = useNavigate();

  const routeChange = () => {
    let path = "/user/" + user.data.id + "/profile";
    navigate(path);
  };

  function logout() {
    const cookies = new Cookies();
    cookies.remove("DataNoodleSession");
    sessionStorage.clear();
    localStorage.clear();
    window.location = "/";
  }

  if (user === null) {
    const postOptions = {
      postRoute: "/user",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      let res = JSON.stringify(result);
      sessionStorage.setItem("user", res);
      setUser(result);
    });
  }

  const menuItems = [
    {
      key: "1",
      label: (
        <div style={{ textAlign: "center" }}>
          <div className="profile-menu-text">
            {user && user.data.first_name} {user && user.data.last_name}
          </div>
          <div className="has-text-grey">{user && user.data.email}</div>
        </div>
      ),
    },
    {
      key: "2",
      label: <span onClick={routeChange}>View Profile</span>,
      icon: <img src="/images/person.png" alt="profile icon" />,
    },
    {
      key: "5",
      label: <span onClick={() => logout()}>Logout</span>,
      icon: <img src="/images/logout.png" alt="logout icon" />,
    },
  ];

  return (
    <Dropdown
      menu={{
        items: menuItems,
      }}
      className="navbar-item"
      overlayClassName="profile-dropdown"
    >
      <div>
        <Space>
          {user !== null && user.data.first_name} {user !== null && user.data.last_name}
          <DownOutlined />
        </Space>
      </div>
    </Dropdown>
  );
}
