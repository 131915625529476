import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useGetAllEcosystemsOfCurrentUserQuery = () => {
  return useQuery(["ecosystems"], fetchAllEcosystemsOfCurrentUser);
};

const fetchAllEcosystemsOfCurrentUser = async () => {
  const accessTokenResult = await setAccessToken();
  let response = {};
  if (accessTokenResult) {
    response = await axiosClient.get("/ecosystems");
  }
  return response?.data?.data || [];
};

export const useGetEcosystemIdByUuidQuery = (uuid) => {
  return useQuery(
    ["ecosystem-uuid-to-id-mapper", uuid],
    () => fetchEcosystemIdByUuid(uuid),
    {
      enabled: uuid !== undefined,
    }
  );
};

const fetchEcosystemIdByUuid = async (uuid) => {
  await setAccessToken();
  const response = await axiosClient.get(`/ecosystems/${uuid}`);
  return response?.data?.id;
};
