import { Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useCallback, useState } from "react";
import { useCreateEcosystem } from "../../../utils/Mutations/EcosystemMutation";
import "./index.scss";
import { industries } from "../../../constants/EcosystemIndustries";
import { useSelector } from "react-redux";

const { Option } = Select;

const CreateEcosystemButton = ({ backgroundColor = "#fff", teamId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currencyId, setCurrencyId] = useState("");
  const [ecosystemName, setEcosystemName] = useState("");
  const [hasError, setHasError] = useState(false);
  const [industryName, setIndustryName] = useState("Other");
  const { mutate: createEcosystem } = useCreateEcosystem();
  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);
  const { currencies, loading: CurrencyLoading } = useSelector((state) => state.currencies);

  const handleOk = useCallback(() => {
    const hasError = ecosystemName === "";
    setHasError(hasError);

    if (!hasError) {
      setIsModalVisible(false);
      createEcosystem({
        ecosystemName: ecosystemName,
        teamId: teamId,
        industryName: industryName,
        currencyId: currencyId,
      });
      setHasError(false);
    }
    setEcosystemName("");
  }, [createEcosystem, ecosystemName, industryName, teamId, currencyId]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleIndustryOnChange = useCallback((value, option) => {
    setIndustryName(option.children);
  }, []);

  const handleCurrencyOnChange = useCallback((value, option) => {
    setCurrencyId(value);
  }, []);

  const handleEcosystemNameOnChange = useCallback((e) => {
    setEcosystemName(e.target.value);
  }, []);


  return (
    <>
      <Button
        className="add-ecosystem-btn"
        onClick={showModal}
        style={{ backgroundColor: backgroundColor }}
      >
        + Ecosystem
      </Button>
      <Modal
        closable={false}
        title={
          <Row align="top" justify="space-between">
            <Col>
              <p className="add-ecosystem-modal-title">Add Ecosystem</p>
            </Col>
            <Col>
              <img
                src="images/add-ecosystem-modal-icon.png"
                alt="Add Ecosystem modal icon"
                width="60"
                height="60"
              />
            </Col>
          </Row>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        wrapClassName="add-ecosystem-modal"
        centered
      >
        <div className="ecosystem">
          <p className="title">Ecosystem Name</p>
          <Input
            placeholder="Example: Ecosystem Name"
            className={`ecosystem-name modal-item ${
              hasError ? "border-red" : ""
            }`}
            onChange={handleEcosystemNameOnChange}
            value={ecosystemName}
          />
        </div>
        <div className="industry modal-item">
          <Col span={15} className="title">
            Industry
          </Col>
          <Col span={15}>
            <Select
              defaultValue={"Please Select"}
              onChange={handleIndustryOnChange}
              className="modal-dropdown"
              dropdownClassName="modal-dropdown-item"
              bordered={false}
            >
              {Object.entries(industries).map(([industryName, industryValue]) => (
                <Option key={industryValue} value={industryValue}>
                  {industryName}
                </Option>
              ))}
            </Select>
          </Col>
        </div>
        <div className="currency modal-item">
          <Col span={15} className="title">
            Base Currency
          </Col>
          <Col span={15}>
            {CurrencyLoading ? (
              <div>Loading currencies...</div>
            ) : (
              <Select
                defaultValue={"Please Select currency"}
                onChange={handleCurrencyOnChange}
                className="modal-dropdown"
                dropdownClassName="modal-dropdown-item"
                bordered={false}
              >
                {currencies.map((currency) => (
                  <Option
                    key={currency.id}
                    value={currency.id}
                    className="modal-dropdown-item"
                  >
                    {currency.name}
                  </Option>
                ))}
              </Select>
            )}
          </Col>
          <Col span={15} className="sub-title">
            Datanoodle will prioritise this currency to track.
          </Col>
        </div>
      </Modal>
    </>
  );
};

export default CreateEcosystemButton;
