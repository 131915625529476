import React, { useCallback, useMemo, useState } from "react";
import { Select } from "antd";
import "./ProductDetailChart.scss";
import PriceHistoryChart from "./PriceHistoryChart";
import RevenueChart from "./RevenueChart";
import ConversionRateChart from "./ConversionRateChart";
import { useGetProductRevenueStatistics, useGetWebsitePageStatistics } from "../../../utils/Queries/GoogleAnalyticsStatisticsQueries";
import { useSelector } from "react-redux";
import TrafficChart from "./TrafficChart";

let maxRevenue = 0;
let maxConversion = 0;
let maxTraffic = 0;

const ProductDetailChart = ({ data, ecosystemId, websiteId, productId, webpageId, hasAnalytics }) => {
  const PRICE_HISTORY = useMemo(() => "priceHistory", []);
  const REVENUE = useMemo(() => "revenue", []);
  const CONVERSION_RATE = useMemo(() => "conversionRate", []);
  const TRAFFIC = useMemo(() => "traffic", []);

  const [selectedChart, setSelectedChart] = useState(PRICE_HISTORY);

  const datePicker = useSelector((state) => state.productTracking.datePicker);

  const { data: productRevenueData } = useGetProductRevenueStatistics(
    datePicker,
    ecosystemId,
    websiteId,
    productId,
  );

  const { data: trafficData } = useGetWebsitePageStatistics(
    datePicker,
    ecosystemId,
    websiteId,
    webpageId,
    selectedChart === TRAFFIC
  );

  const revenueData = useMemo(
    () =>
      productRevenueData?.data?.map((product) => {
        maxRevenue = Math.max(maxRevenue, product.total_revenue);
        return {
          date: product.date,
          x: product.total_revenue,
          changes: product.change,
        };
      }),
    [productRevenueData],
  );

  const conversionData = useMemo(
    () =>
      productRevenueData?.data?.map((product) => {
        maxConversion = Math.max(maxConversion, product.total_conversions);
        return {
          date: product.date,
          x: product.total_conversions,
          changes: product.change,
        };
      }),
    [productRevenueData?.data],
  );

  const trafficChartData = useMemo(
    () =>
      trafficData?.data?.map((item) => {
        maxTraffic = Math.max(maxTraffic, item.organic_views, item.paid_views, item.direct_views, item.email_views, item.referral_views, item.social_views);
        return {
          date: item.date,
          a: item.organic_views,
          b: item.paid_views,
          c: item.direct_views,
          d: item.email_views,
          e: item.referral_views,
          f: item.social_views,
        };
      }),
    [trafficData]
  );

  const trafficLegendData = useMemo(
    () => trafficData?.totals,
    [trafficData?.totals]
  );

  const tickConfig = useMemo(() => {
    return {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      color: "#7e8e9f",
    };
  }, []);

  const handleChange = (value) => {
    setSelectedChart(value);
  };

  const renderChart = useCallback(() => {
    switch (selectedChart) {
      case PRICE_HISTORY:
        return <PriceHistoryChart data={data} tickConfig={tickConfig} />;
      case REVENUE:
        return (
          <RevenueChart
            data={revenueData}
            tickConfig={tickConfig}
            max={maxRevenue}
            total={productRevenueData?.totals?.total_revenue}
          />
        );
      case CONVERSION_RATE:
        return (
          <ConversionRateChart
            data={conversionData}
            tickConfig={tickConfig}
            max={maxConversion}
            total={productRevenueData?.totals?.total_conversions}
          />
        );
      case TRAFFIC:
        return (
          <TrafficChart
            data={trafficChartData}
            tickConfig={tickConfig}
            legendData={trafficLegendData}
            max={maxTraffic}
          />
        );
      default:
        return <p>Something wrong</p>;
    }
  }, [
    selectedChart,
    PRICE_HISTORY,
    data,
    tickConfig,
    REVENUE,
    revenueData,
    productRevenueData?.totals?.total_revenue,
    productRevenueData?.totals?.total_conversions,
    CONVERSION_RATE,
    conversionData,
    TRAFFIC,
    trafficChartData,
    trafficLegendData,
  ]);

  return (
    <div className="product-detail-chart card">
      <Select
        className="product-detail-chart-select"
        bordered={false}
        defaultValue={selectedChart}
        onChange={handleChange}
        options={hasAnalytics ? [
          {
            value: PRICE_HISTORY,
            label: "Price History",
          },
          {
            value: REVENUE,
            label: "Revenue",
          },
          {
            value: CONVERSION_RATE,
            label: "Conversion Rate",
          },
          {
            value: TRAFFIC,
            label: "Traffic",
          },
        ] : [
          {
            value: PRICE_HISTORY,
            label: "Price History",
          },
        ]}
      />
      {renderChart()}
    </div>
  );
};

export default ProductDetailChart;
