import { Col, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import { getCurrentDate } from "../../../../utils/DateTimeUtil";
import "./index.scss";
import { format } from "date-fns";
import { CHART_TYPE_AVAILABILITY } from "../../../../constants/ChartType";

const MainChartFilter = ({
  chartTypeLabels,
  dateFrom,
  handleSelectChartType,
  ecosystem,
}) => {
  return (
    <Row className="main-chart-filter" align="middle">
      <Col lg={{ span: 3 }} md={{ span: 4 }}>
        <Select
          className="main-chart-filter-select"
          defaultValue={CHART_TYPE_AVAILABILITY}
          bordered={false}
          onChange={handleSelectChartType}
        >
          {chartTypeLabels &&
            chartTypeLabels.map((chartTypeLabel) => (
              <Option
                key={chartTypeLabel}
                value={chartTypeLabel}
                className="main-chart-filter-option"
              >
                {chartTypeLabel}
              </Option>
            ))}
        </Select>
      </Col>
      <Col className="main-chart-filter-date-range">
        {dateFrom === ""
          ? ecosystem?.created_at !== undefined
            ? format(new Date(ecosystem?.created_at), "dd/MM/yyyy")
            : ""
          : format(new Date(dateFrom), "dd/MM/yyyy")}{" "}
        - {format(new Date(getCurrentDate()), "dd/MM/yyyy")}
      </Col>
    </Row>
  );
};

export default MainChartFilter;
