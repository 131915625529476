import React, { useState, useEffect } from "react";
import {
  Link,
  Navigate,
  // Link
} from "react-router-dom";
import SubHeader from "../components/Layout/SubHeader";
import Footer from "../components/Layout/Footer";
import Loading from "../components/Animations/Loading";
import MessageModal from "../../components/Modals/MessageModal";
import MainSection from "../components/MainSection/MainSection";
import Header from "../components/Layout/Header";
import { useParams } from "react-router-dom";
import {
  useGetAllEcosystemsOfCurrentUserQuery,
  useGetEcosystemIdByUuidQuery,
} from "../utils/Queries/EcosystemQueries";
import { LoggedInStatus } from "../services/LoggedInStatus";
import MainSectionTitle from "../components/MainSectionTitle/MainSectionTitle.js";
import { Row } from "antd";
import BarChartContainer from "../components/Tracking/Chart/TrackingBarChartContainer";
import TrackingBarChart from "../components/Tracking/Chart/TrackingBarChart";
import {
  useGetPriceChangeChartQuery,
  useGetAvailabilityChangeChartQuery,
  useGetPriceChangeTableQuery,
  useGetAvailabilityChangeTableQuery,
  useGetNewPagesQuery,
  useGetNewPagesTableQuery,
  useGetNewProductsQuery,
  useGetNewProductsTableQuery,
  useGetTotalsQuery,
} from "../utils/Queries/TrackingQueries";
import { NumberTooltip, LabelAndNumberTooltip } from "../components/Tracking/Chart/Tooltip";
import TrackingCol from "../components/Tracking/TrackingCol";
import TrackingTableContainer from "../components/Tracking/Table/TrackingTableContainer";
import TrackingTable from "../components/Tracking/Table/TrackingTable";
import { shortenText, splitByCapitalLetter } from "../utils/StringUtil";
import Section from "../components/Tracking/Section";
import SectionTitle from "../components/Tracking/Section/SectionTitle";
import { CustomizedAxisTick } from "../components/Tracking/Chart/Tick";

const TrackingDashboard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [ecosystems, setEcosystems] = useState([]);
  const [error] = useState(false);
  const [ecosystemName, setEcosystemName] = useState("");
  const { ecosystemUuid, websiteId } = useParams();
  const [websites] = useState(null);
  const [newPagesData, setNewPagesData] = useState([]);
  const [newProductsData, setNewProductsData] = useState([]);
  const [priceChangePage, setPriceChangePage] = useState(1);
  const [availabilityChangePage, setAvailabilityChangePage] = useState(1);
  const [totalWebpagesData, setTotalWebpagesData] = useState([]);
  const [totalProductsData, setTotalProductsData] = useState([]);
  const [priceChangeData, setPriceChangeData] = useState([]);
  const [newPagePage, setNewPagePage] = useState(1);
  const [newProductPage, setNewProductPage] = useState(1);
  const [availabilityChangeData, setAvailabilityChangeData] = useState([]);

  const { data: ecosystemId } = useGetEcosystemIdByUuidQuery(ecosystemUuid);

  const { data: allEcosystemOfUser, isSuccess: isSuccessAllEcosystemOfUser } =
    useGetAllEcosystemsOfCurrentUserQuery();

  const { data: newPages, isLoading: isNewPagesLoading } =
    useGetNewPagesQuery(ecosystemId);

  const { data: newProducts, isLoading: isNewProductsLoading } =
    useGetNewProductsQuery(ecosystemId);

  const { data: newPagesTable, isLoading: isNewPagesTableLoading } =
    useGetNewPagesTableQuery(ecosystemId, newPagePage);

  const { data: newProductsTable, isLoading: isNewProductsTableLoading } =
    useGetNewProductsTableQuery(ecosystemId, newProductPage);

  const { data: priceChangeChart, isLoading: isPriceChangeChartLoading } =
    useGetPriceChangeChartQuery(ecosystemId);

  const { data: priceChangeTable, isLoading: isPriceChangeTableLoading } =
    useGetPriceChangeTableQuery(ecosystemId, priceChangePage);

  const {
    data: availabilityChangeChart,
    isLoading: isAvailabilityChangeChartLoading,
  } = useGetAvailabilityChangeChartQuery(ecosystemId);

  const {
    data: availabilityChangeTable,
    isLoading: isAvailabilityChangeTableLoading,
  } = useGetAvailabilityChangeTableQuery(ecosystemId, availabilityChangePage);

  const { data: totals, isLoading: isTotalsLoading } =
    useGetTotalsQuery(ecosystemId);

  const newPagesTableColumns = [
    {
      title: "Page URL",
      dataIndex: "url",
      key: "url",
      render: (text, record) => (
        <Link to={record.internal_url} alt="Page URL" className="link">
          {shortenText(text)}
        </Link>
      ),
    },
    {
      title: "Page Title",
      dataIndex: "last_title",
      key: "last_title",
      render: (text) => shortenText(text),
    },
    {
      title: "Website",
      dataIndex: "website_name",
      key: "website_name",
    },
  ];

  const newProductsTableColumns = [
    {
      title: "Product SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text, record) => (
        <Link to={record.internal_url} alt="Product SKU" className="link">
          {text}
        </Link>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
      render: (availability) => {
        if (availability != null) {
          return splitByCapitalLetter(availability).join(" ");
        } else {
          return "NA";
        }
      },
    },
    {
      title: "Website",
      dataIndex: "website_name",
      key: "website_name",
    },
  ];

  const priceChangeTableColumns = [
    {
      title: "Product SKU",
      dataIndex: "last_sku",
      key: "last_sku",
      render: (text, record) => (
        <Link
          to={`/ecosystem/${ecosystemUuid}/websites/${record.website_id}/products/${record.id}/revisions`}
          alt="Product SKU"
          className="link"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Price",
      dataIndex: "last_price",
      key: "last_price",
    },
    {
      title: "% Change",
      dataIndex: "price_change",
      key: "price_change",
      render: (priceChange) => (
        <span
          className={
            priceChange > 0 ? "change-up" : priceChange < 0 ? "change-down" : ""
          }
        >
          {priceChange === "NA"
            ? priceChange
            : `${Number(priceChange)?.toFixed(2)} %`}
        </span>
      ),
    },
    {
      title: "Website",
      dataIndex: "website_name",
      key: "website_name",
    },
  ];

  const availabilityChangeTableColumns = [
    {
      title: "Product SKU",
      dataIndex: "last_sku",
      key: "last_sku",
      render: (text, record) => (
        <Link
          to={`/ecosystem/${ecosystemUuid}/websites/${record.website_id}/products/${record.id}/revisions`}
          alt="Product SKU"
          className="link"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Availability",
      dataIndex: "last_availability",
      key: "last_availability",
      render: (availability) => splitByCapitalLetter(availability).join(" "),
    },
    {
      title: "Website",
      dataIndex: "website_name",
      key: "website_name",
    },
  ];

  useEffect(() => {
    if (!isNewPagesLoading) {
      setNewPagesData(
        newPages?.map((item) => {
          return {
            owner: item.owner,
            quantity: item.new_pages,
            website_name: item.website_name,
          };
        }),
      );
    }
  }, [isNewPagesLoading, newPages]);

  useEffect(() => {
    if (!isNewProductsLoading) {
      setNewProductsData(
        newProducts?.map((item) => {
          return {
            owner: item.owner,
            quantity: item.new_products,
            website_name: item.website_name,
          };
        }),
      );
    }
  }, [isNewProductsLoading, newProducts]);

  useEffect(() => {
    if (!isTotalsLoading) {
      setTotalWebpagesData(
        totals?.webpages?.map((item) => {
          return {
            owner: item.owner_id === 1 ? true : false,
            quantity: item.total,
            website_name: item.website_name,
          };
        }),
      );
    }
  }, [isTotalsLoading, totals, totals?.webpages]);

  useEffect(() => {
    if (!isTotalsLoading) {
      setTotalProductsData(
        totals?.products?.map((item) => {
          return {
            owner: item.owner_id === 1 ? true : false,
            quantity: item.total,
            website_name: item.website_name,
          };
        }),
      );
    }
  }, [isTotalsLoading, totals, totals?.products]);

  useEffect(() => {
    if (!isPriceChangeChartLoading) {
      setPriceChangeData(
        priceChangeChart?.map((item) => {
          return {
            owner: item.owner,
            quantity: item.new_products,
            website_name: item.website_name,
          };
        }),
      );
    }
  }, [isPriceChangeChartLoading, priceChangeChart]);

  useEffect(() => {
    if (!isAvailabilityChangeChartLoading) {
      setAvailabilityChangeData(
        availabilityChangeChart?.map((item) => {
          return {
            owner: item.owner,
            quantity: item.new_products,
            website_name: item.website_name,
          };
        }),
      );
    }
  }, [availabilityChangeChart, isAvailabilityChangeChartLoading]);

  useEffect(() => {
    setIsAuthenticated(LoggedInStatus());
    if (isSuccessAllEcosystemOfUser) {
      setEcosystems(allEcosystemOfUser);
    }
    sessionStorage.setItem("ecosystemId", ecosystemId);
    isSuccessAllEcosystemOfUser &&
    allEcosystemOfUser.forEach((ecosystem) => {
      if (ecosystem?.id === parseInt(ecosystemId)) {
        setEcosystemName(ecosystem?.name);
      }
    });
  }, [allEcosystemOfUser, isSuccessAllEcosystemOfUser, ecosystemId]);

  const updateLogin = (val) => {
    setIsAuthenticated(val);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const subHeader = (websiteId) => {
    if (websiteId > 0) {
      let _webisteId = parseInt(websiteId);
      return websites.data[0].find((w) => w.id === _webisteId).name;
    }
  };

  return (
    <>
      {isAuthenticated === false ||
      isAuthenticated === "exit" ||
      isAuthenticated === undefined ? (
        <Navigate to="/" />
      ) : isAuthenticated === null ? ( // need revisit
        <Loading pad={200} />
      ) : error !== false ? (
        <MessageModal modalState={this.errorModalState} message={error} />
      ) : (
        <>
          <div>
            <Header
              updateLogin={updateLogin}
              logout={logout}
              loggedIn={isAuthenticated}
              ecosystems={isSuccessAllEcosystemOfUser && ecosystems}
              ecosystemId={ecosystemId}
            />
            <SubHeader
              ecosystemName={ecosystemName}
              ecosystemId={ecosystemUuid}
              websiteId={websiteId}
              websiteName={subHeader(websiteId)}
            />
          </div>

          <MainSection>
            <MainSectionTitle title="Tracking Dashboard" />

            <Section>
              <SectionTitle>Total Pages and Products</SectionTitle>
              <Row gutter={[16, 16]}>
                <TrackingCol>
                  <BarChartContainer
                    title="Total Pages"
                    description="Total pages discovered"
                  >
                    <TrackingBarChart
                      data={totalWebpagesData}
                      isLoading={isTotalsLoading}
                      customTooltip={<LabelAndNumberTooltip />}
                      customTick={<CustomizedAxisTick />}
                    />
                  </BarChartContainer>
                </TrackingCol>
                <TrackingCol>
                  <BarChartContainer
                    title="Total Products"
                    description="Total products discovered"
                  >
                    <TrackingBarChart
                      data={totalProductsData}
                      isLoading={isTotalsLoading}
                      customTooltip={<LabelAndNumberTooltip />}
                    />
                  </BarChartContainer>
                </TrackingCol>
              </Row>
            </Section>

            <Section>
              <SectionTitle>Page and Product tracking</SectionTitle>
              <Row gutter={[16, 16]}>
                <TrackingCol>
                  <BarChartContainer
                    title="New Pages"
                    description="New pages discovered in the last 30 days"
                  >
                    <TrackingBarChart
                      data={newPagesData}
                      isLoading={isNewPagesLoading}
                      customTooltip={<LabelAndNumberTooltip />}
                    />
                  </BarChartContainer>
                  <TrackingTableContainer>
                    <TrackingTable
                      columns={newPagesTableColumns}
                      dataSource={newPagesTable?.data}
                      isLoading={isNewPagesTableLoading}
                      paginationConfig={{
                        current: newPagesTable?.currentPage,
                        total: newPagesTable?.total,
                      }}
                      setPage={setNewPagePage}
                    />
                  </TrackingTableContainer>
                </TrackingCol>
                <TrackingCol>
                  <BarChartContainer
                    title="New Products"
                    description="New products discovered in the last 30 days"
                  >
                    <TrackingBarChart
                      data={newProductsData}
                      isLoading={isNewProductsLoading}
                      customTooltip={<LabelAndNumberTooltip />}
                    />
                  </BarChartContainer>
                  <TrackingTableContainer>
                    <TrackingTable
                      columns={newProductsTableColumns}
                      dataSource={newProductsTable?.data}
                      isLoading={isNewProductsTableLoading}
                      paginationConfig={{
                        current: newProductsTable?.currentPage,
                        total: newProductsTable?.total,
                      }}
                      setPage={setNewProductPage}
                    />
                  </TrackingTableContainer>
                </TrackingCol>
              </Row>
            </Section>

            <Section>
              <SectionTitle> Price and Availability Changes</SectionTitle>
              <Row gutter={[16, 16]}>
                <TrackingCol>
                  <BarChartContainer
                    title="Price Changes"
                    description="Price changes detected in the last 30 days"
                  >
                    <TrackingBarChart
                      data={priceChangeData}
                      isLoading={isPriceChangeChartLoading}
                      customTooltip={<NumberTooltip />}
                    />
                  </BarChartContainer>
                  <TrackingTableContainer>
                    <TrackingTable
                      columns={priceChangeTableColumns}
                      dataSource={priceChangeTable?.data}
                      isLoading={isPriceChangeTableLoading}
                      paginationConfig={{
                        current: priceChangeTable?.currentPage,
                        total: priceChangeTable?.total,
                      }}
                      setPage={setPriceChangePage}
                    />
                  </TrackingTableContainer>
                </TrackingCol>
                <TrackingCol>
                  <BarChartContainer
                    title="Availability Changes"
                    description="Availability changes detected in the last 30 days"
                  >
                    <TrackingBarChart
                      data={availabilityChangeData}
                      isLoading={isAvailabilityChangeChartLoading}
                      customTooltip={<NumberTooltip />}
                    />
                  </BarChartContainer>
                  <TrackingTableContainer>
                    <TrackingTable
                      columns={availabilityChangeTableColumns}
                      dataSource={availabilityChangeTable?.data}
                      isLoading={isAvailabilityChangeTableLoading}
                      paginationConfig={{
                        current: availabilityChangeTable?.currentPage,
                        total: availabilityChangeTable?.total,
                      }}
                      setPage={setAvailabilityChangePage}
                    />
                  </TrackingTableContainer>
                </TrackingCol>
              </Row>
            </Section>
          </MainSection>
          <Footer />
        </>
      )}
    </>
  );
};

export default TrackingDashboard;
